<template>
  <div class="container">
    <div class="top">
      <div class="top_left">
        <div class="h_title"> {{topInfo.title_1}} </div>
        <div class="s_title"> {{topInfo.title_2}} </div>
        <div class="text"> {{topInfo.text}} </div>
      </div>
      <div class="top_right">
        <img class="left_img" :src="topInfo.img_1">
        <img class="right_img" :src="topInfo.img_2">
      </div>
    </div>
    <div class="mid">
      <div class="title"> {{midInfo.title}} </div>
      <div class="mid_box">
        <div class="item_box" v-for="item in midInfo.list" :key="item">
          <div class="item_box_title" :class="item.id == 3 ?  'item_right_text' :  item.id == 4 ? 'item_left_text' : '' "> {{item.title}} </div>
          <img :src="item.img">
        </div>
      </div>
    </div>
    <!--链接患者和服务...-->
    <div class="flow">
      <div class="title"> {{flowInfo.headLine}} </div>
      <div class="flow_box">
        <div class="flow_item">
          <div class="flow_item_box" v-for="(item, index) in flowInfo.list" :key="index" :class="index > 4 ? 'margin144': ''">
            <img class="flow_item_img" :src="item.img">
            <div class="flow_item_radius" :class="index == 0 ? 'flow_item_radius_1': '' "></div>
            <div class="flow_item_title"> {{item.title}} </div>
            <div class="flow_item_text"> {{item.text}} </div>
          </div>
          <img class="flow_line" :src="flowInfo.imgLine">
        </div>
      </div>
    </div>
    <!--建档应用-->
    <div class="build">
      <div class="build_left">
        <img class="left_img" :src="buildInfo.img_1">
        <img class="right_img" :src="buildInfo.img_2">
      </div>
      <div class="build_right">
        <div class="head_line"> {{buildInfo.headLine}} </div>
        <div class="head_title"> {{buildInfo.headTitle}} </div>
        <div class="head_intro"> {{buildInfo.headIntro}} </div>
        <div class="text_item_box" v-for="item in buildInfo.list" :key="item">
          <div class="text_item_num"> {{item.icon}} </div>
          <div class="text_item_text"> {{item.text}} </div>
        </div>
      </div>
    </div>
    <!--医保实时结算-->
    <div class="settlement">
      <div class="settlement_left">
        <div class="head_line"> {{settlementInfo.headLine}} </div>
        <div class="head_title"> {{settlementInfo.headTitle}} </div>
        <div class="head_text"> {{settlementInfo.headText}} </div>
      </div>
      <img class="settlement_right" :src="settlementInfo.img">
    </div>
    <!--移动查房-->
    <div class="wards">
      <img class="wards_left" :src="wardsInfo.img">
      <div class="wards_right">
        <div class="head_line"> {{wardsInfo.headLine}} </div>
        <div class="head_title"> {{wardsInfo.headTitle}} </div>
        <div class="head_text"> {{wardsInfo.headText}} </div>
      </div>
    </div>
    <!--在线问诊-->
    <div class="inquiry">
      <div class="head_line">  {{inquiryInfo.headLine}} </div>
      <div class="head_title"> {{inquiryInfo.headTitle}} </div>
      <div class="inquiry_item">
        <div class="inquiry_item_box" v-for="(item, index) in inquiryInfo.list" :key="index">
          <img class="inquiry_item_img" :src="item.img">
          <div class="inquiry_item_radius" :class="index == 0 ? 'inquiry_item_radius_1': '' "></div>
          <div class="inquiry_item_text"> {{item.text}} </div>
        </div>
        <div class="inquiry_item_line"></div>
      </div>
      <div class="img_box">
        <img class="img_box_item" :src="inquiryInfo.img_1">
        <img class="img_box_mid" :src="inquiryInfo.img_2">
        <img class="img_box_item" :src="inquiryInfo.img_3">
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: "product_ylfw",
    data(){
      return{
        topInfo:{
          title_1: '「互联网+」医疗服务平台',
          title_2: '为全国实体医院搭建诊前、诊中、诊后的线上线下全流程「互联网 + 」医疗服务平台。',
          text: '小程序为载体，连接患者、医生端，为医院打造线上线下统一入口。区别于传统智慧医院单一产品服务形态，海南玉螺借助腾讯科技生态优势，不断深延医疗服务场景，持续丰富医院服务业态，满足患者多元就医需求。',
          img_1: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_hlwj_phone_1.png',
          img_2: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_hlwj_phone_2.png'
        },
        midInfo:{
          title:'打造医院品牌形象，传播科室最强口碑',
          list:[
            {
              id: 1,
              title: '拓宽宣传渠道',
              img: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_hlwj_bg_1.png'
            },
            {
              id: 2,
              title: '建立科室品牌',
              img: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_hlwj_bg_2.png'
            },
            {
              id: 3,
              title: '建立医生形象',
              img: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_hlwj_bg_3.png'
            },
            {
              id: 4,
              title: '提升医患服务',
              img: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_hlwj_bg_4.png'
            },
          ],
        },
        flowInfo:{
          headLine: '连接患者和服务，覆盖诊前、诊中、诊后全流程',
          list:[
            {
              text: '刷脸建档',
              img: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_icon_sljd.png',
            },
            {
              text: '智能导诊',
              img: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_icon_zndz.png',
            },
            {
              text: '挂号检查',
              img: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_icon_ghjc.png',
            },
            {
              text: '门诊缴费',
              img: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_icon_mzjf.png',
            },
            {
              text: '预约检查',
              img: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_icon_yyjc.png',
            },
            {
              text: '健康管理',
              img: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_icon_jkgl.png',
            },
            {
              text: '智能随访',
              img: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_icon_znsf.png',
            },
            {
              text: '处方流转',
              img: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_icon_cflz.png',
            },
            {
              text: '报告查询',
              img: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_icon_bgcx.png',
            },
            {
              text: '诊间支付',
              img: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_icon_zjzf.png',
            },

          ],
          imgLine: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_line.png'
        },
        buildInfo:{
          img_1: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_hlwj_phone_3.png',
          img_2: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_hlwj_phone_4.png',
          headLine: '国内首家实现',
          headTitle: '刷脸建档应用',
          headIntro: '人脸活体认证，高效安全',
          list: [
            {
              icon: '①',
              text: '利用腾讯慧眼技术实现与全国人口数据库进行人脸识别对比，高效安全；'
            },
            {
              icon: '②',
              text: '以电子健康卡/电子医保凭证/就诊卡多卡合一为底层技术、形成患者统一ID，一码通行'
            },
            {
              icon: '③',
              text: '就诊记录随时看，省去携带病历本的烦恼'
            },
            {
              icon: '④',
              text: '支持新生儿建档'
            },

          ]
        },
        settlementInfo:{
          headLine: '国内首家实现',
          headTitle: '医保实时结算',
          headText: '打通患者端、医院端、医保局三方环节， 线上全流程支付   一键操作省时省力。',
          img: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_img_ybjs.png'
        },
        wardsInfo:{
          headLine: '国内首家实现',
          headTitle: '移动查房',
          headText: '打破医生管理患者在时间空间上的限制，实现线上实时监控患者病情，及时响应患者需求。',
          img: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_hlwj_phone_5.png'
        },
        inquiryInfo:{
          headLine: '在线问诊',
          headTitle: '看病、开药无需跑医院',
          list:[
            {
              text: '智能导诊',
              img: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_icon_zndz.png',
            },
            {
              text: '选择医生/预约时间',
              img: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_icon_yysj.png',
            },
            {
              text: '医生咨询',
              img: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_icon_yszx.png',
            },
            {
              text: '开方购药',
              img: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_icon_kfgy.png',
            },
            {
              text: '药品配送',
              img: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_icon_ypps.png',
            },
            {
              text: '健康管理服务',
              img: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_icon_jkglfw.png',
            },
          ],
          img_1: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_hlwj_phone_6.png',
          img_2: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_hlwj_phone_7.png',
          img_3: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_hlwj_phone_8.png',
        }

      }
    },
    mounted(){
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };
</script>

<style lang="stylus" scoped>
  .container{
    background #F5F5F7
    font-family Alibaba PuHuiTi 2.0
    font-weight normal
    box-sizing border-box
    padding 40px 140px

    .top{
      display flex
      align-items center
      justify-content space-between

      .top_left{
        display flex
        flex-flow column
        width 703px

        .h_title{
          font-size 56px
          line-height 67px
          color #0069D9
        }
        .s_title{
          font-size 32px
          line-height 45px
          color #343A40
          margin-top 80px
        }
        .text{
          margin-top 30px
          font-size 20px
          line-height 34px
          color #6C757D
        }
      }
      .top_right{
        display flex
        .left_img{
          width 317px
          height 800px
        }
        .right_img{
          margin 84px 0 0 48px
          width 309px
          height 800px
        }
      }
    }
    .mid{
      display flex
      flex-flow column
      justify-content space-between
      margin-top 76px
      box-sizing border-box
      padding 150px 0
      .title{
        font-size 56px
        line-height 67px
        color #23272B
        text-align center
      }
      .mid_box{
        margin-top 110px
        display flex
        flex-wrap wrap
        justify-content space-between
        .item_box{
          margin-top 40px
          width 800px
          height 400px
          position relative
          img{
            width 100%
            height 100%
          }
          .item_box_title{
            width 100%
            font-size 40px
            line-height 56px
            color #343A40
            position absolute
            text-align center
            margin-top 30px
          }
          .item_right_text{
            width 100%
            box-sizing border-box
            padding-right 50px
            text-align right
          }
          .item_left_text{
            width 100%
            box-sizing border-box
            padding-left 50px
            text-align left
          }
        }
      }
    }
    .flow{
      display flex
      flex-flow column
      box-sizing border-box
      padding 100px 0
      .title{
        font-size 56px
        line-height 67px
        color #23272B
        text-align center
      }
      .flow_box{
        background #ffffff
        border-radius 24px
        margin-top 117px
        .flow_item{
          display flex
          flex-wrap wrap
          box-sizing border-box
          padding 80px 80px 80px 0
          position relative
          .margin144{
            margin-top 206px
          }
          .flow_item_box{
            display flex
            flex-flow column
            align-items center
            text-align center
            width 20%
            .flow_item_img{
              width 80px
              height 80px
            }
            .flow_item_radius{
              width 10px
              height 10px
              background #ffffff
              border-radius 50%
              border 5px solid #007BFF
              margin 30px 0 20px 0
              z-index 666
            }
            .flow_item_radius_1{
              background #007BFF
              z-index 666
            }
            .flow_item_title{
              font-size 28px
              line-height 40px
              color #343A40
              width 166px
            }
            .flow_item_text{
              font-size 20px
              line-height 40px
              color #343A40
              width 200px
            }
          }

          .flow_line{
            height 400px
            position absolute
            bottom 148px
            left 160px
          }
        }
      }
    }
    .build{
      display flex
      justify-content space-around
      align-items center
      .build_left{
        display flex
        .left_img{
          width 316px
          height 800px
        }
        .right_img{
          margin 70px 0 0 70px
          width 316px
          height 800px
        }
      }
      .build_right{
        display flex
        flex-flow column
        width 536px
        .head_line{
          font-size 32px
          line-height 45px
          color #343A40
        }
        .head_title{
          font-size 56px
          line-height 67px
          color #0069D9
          margin-top 10px
        }
        .head_intro{
          font-size 28px
          margin 40px 0 15px 0
          line-height 40px
          color #343A40
        }
        .text_item{
          display flex
          font-size 20px
          line-height 34px
          color #6C757D
          margin-top 14px
          .text_item_num{
            margin-right 10px
          }
          .text_item_text{

          }
        }
        .text_item_box{
          display flex
          .text_item_text{
            margin-left 10px
          }
        }
      }
    }
    .settlement{
      margin-top 250px
      display flex
      justify-content space-around
      align-items center
      background #ffffff
      border-radius 24px
      .settlement_left{
        display flex
        flex-flow column
        text-align right
        .head_line{
          font-size 32px
          line-height 45px
          color #343A40
          }
        .head_title{
          font-size 56px
          line-height 67px
          color #0069D9
          margin-top 10px
        }
        .head_text{
          font-size 20px
          line-height 28px
          color #6C757D
          margin-top 40px
        }
      }
      .settlement_right{
        width 600px
        height 565px
        margin 50px 0 47px 0
      }
    }
    .wards{
      margin 200px 0 100px 0
      display flex
      justify-content space-around
      align-items center
      background #ffffff
      border-radius 24px
      .wards_left{
        width 536px
        height 612px
        margin-top 50px
      }
      .wards_right{
        display flex
        flex-flow column
        text-align left
        .head_line{
          font-size 32px
          line-height 45px
          color #343A40
        }
        .head_title{
          font-size 56px
          line-height 67px
          color #0069D9
          margin-top 10px
        }
        .head_text{
          font-size 20px
          line-height 28px
          color #6C757D
          margin-top 40px
        }
      }
    }
    .inquiry{
      display flex
      flex-flow column
      box-sizing border-box
      padding 200px 0 100px 0
      .head_line{
        font-size 56px
        line-height 67px
        color #0069D9
        text-align center
      }
      .head_title{
        font-size 32px
        line-height 45px
        color #343A40
        text-align center
        margin-top 20px
      }
      .inquiry_item{
        display flex
        justify-content space-around
        margin-top 150px
        position relative
        .inquiry_item_box{
          display flex
          flex-flow column
          align-items center
          .inquiry_item_img{
            width 80px
            height 80px
          }
          .inquiry_item_radius{
            width 10px
            height 10px
            background #ffffff
            border-radius 50%
            border 5px solid #007BFF
            margin 30px 0 20px 0
            z-index 666
          }
          .inquiry_item_radius_1{
            background #007BFF
            z-index 666
          }
          .inquiry_item_text{
            font-size 28px
            line-height 40px
            color #343A40
            width 166px
          }
        }
        .inquiry_item_line{
          width 100%
          height 2px
          background #E2E6EA
          position absolute
          top 118px
        }
      }
      .img_box{
        display flex
        justify-content space-around
        margin-top 100px
        .img_box_item{
          margin-top 30px
          width 316px
          height 800px
        }
        .img_box_mid{
          width 430px
          height 860px
        }
      }
    }
  }
</style>
